import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthResponse } from '../models/Auth';
import { UsersResponse, addUserResponse } from '../models/users';
@Injectable({
  providedIn: 'root'
})
export class ApisService {
  BaseUrl ='http://api-super-admin.apishipping.iraq-soft.info/'
  token :string;
  lang :string;
  constructor(private http: HttpClient) { }

  public login(body):  Observable<AuthResponse>{
    // this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    // .set("Authorization","Bearer " + this.token);
    return this.http.post<AuthResponse>(this.BaseUrl+ 'admin/admin_general/login' ,body,{headers});
  }


  public getUsers():  Observable<UsersResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.get<UsersResponse>(this.BaseUrl+ 'admin/admin_auth/all_projects' ,{headers});
  }

  public addUser(body):  Observable<addUserResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<addUserResponse>(this.BaseUrl+ 'admin/admin_auth/add_project' ,body,{headers});
  }


  public editUser(body):  Observable<addUserResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<addUserResponse>(this.BaseUrl+ 'admin/admin_auth/edit_project' ,body,{headers});
  }

  public deleteUser(id : number):  Observable<addUserResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<addUserResponse>(this.BaseUrl+ 'admin/admin_auth/delete_project/'+ id ,null,{headers});
  }

  // public sendNotification(body):  Observable<addUserResponse>{
  //   this.token = localStorage.getItem("token")
  //   this.lang = localStorage.getItem("lang") ??"ar";
  //   let headers: HttpHeaders = new HttpHeaders()
  //   .set("lang" , this.lang)
  //   .set("Authorization","Bearer " + this.token);
  //   return this.http.post<addUserResponse>(this.BaseUrl+ 'admin/admin_auth/multi_notification' ,body,{headers});
  // }

}
