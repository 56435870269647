import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApisService } from 'src/app/services/apis.service';
import { User } from 'src/app/models/users';

@Component({
  selector: 'app-edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls: ['./edit-modal.component.css']
})
export class EditModalComponent implements OnInit {

  @ViewChild( "showModale") showModale;
  @ViewChild("closeButton") closeButton;
  errorMessage :string;
  constructor(private http :ApisService) { }
  @Output() childEdited: EventEmitter<string> =   new EventEmitter();
  @Input() user : User; 
  ngOnInit(): void {
  }

  editUser(body :NgForm){
    this.errorMessage=""
    this.http.editUser(body).subscribe(response=>{ 
          if(response.status == 1 ){
            this.closeButton.nativeElement.click();
            this.childEdited.emit('user Edited Successfully');
          }else{
            this.errorMessage ='* '+ response.message;
          }
    })
}
}
